<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:860px">
            <div slot="header" data-v-step="1">
            <div class="row">
              <div class="col-md-8">
                <strong>Tabelas</strong>
              </div>
              <div class="col-md-4" style="text-align:right;">
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
        <table class="table table-hover">
            <thead>
            <tr>
                <th data-v-step="2" style="width:10px"></th>
                <th style="width:200px">Tabela de Preço</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                      <b-form-checkbox  
                        v-model="emuso1" 
                        switch 
                        size="sm" 
                        disabled 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input :formatter="limitar" class="verde" v-model="tabela1"></b-form-input>
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td>
                      <b-form-checkbox  
                        v-model="emuso2" 
                        switch 
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input :formatter="limitar" class="verde" v-model="tabela2"></b-form-input>
                    </td>
                    <td>
                    </td>
                </tr>
                <tr>
                    <td>
                      <b-form-checkbox  
                        v-model="emuso3" 
                        switch 
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input :formatter="limitar" class="verde" v-model="tabela3"></b-form-input>
                    </td>
                    <td>
                    </td>
                </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">
                  <b-button variant="success" size="sm" @click="editar()">SALVAR ALTERAÇÕES</b-button>
                </th>
              </tr>
            </tfoot>
        </table>

          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Nome de Tabelas">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/hFnGvoKxIu8?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Renomear tabelas',
          },
          content: `Aqui você pode renomear as tabelas de preços para uma melhor organização.`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Pode inclusive desativar as tabelas que não estão em uso. Só não pode desativar a primeira tabela.',
          params: {
            placement: 'top'
          }
        }
      ],
      sessao: '',
      loading: false,
      tabela1: 'Venda Direta',
      tabela2: 'Revendedor',
      tabela3: 'Distribuidor',
      emuso1: true,
      emuso2: true,
      emuso3: true
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('CONFIGURACOES_UNIDADES'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    editar: function () {
      this.loading = true
      // Salva tudo
      this.remover('gp_Metadados', '(module = opcoes_tabelas)').then(
        (k) => {
          var p = []
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'tabela1', module: 'opcoes_tabelas', description: this.tabela1, created_by: this.$session.get('user_ID')} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'tabela2', module: 'opcoes_tabelas', description: this.tabela2, created_by: this.$session.get('user_ID')} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'tabela3', module: 'opcoes_tabelas', description: this.tabela3, created_by: this.$session.get('user_ID')} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'tabela1_ligada', module: 'opcoes_tabelas', description: true, created_by: this.$session.get('user_ID')} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'tabela2_ligada', module: 'opcoes_tabelas', description: this.emuso2, created_by: this.$session.get('user_ID')} ]}, '', '1'))
          p.push(this.inserir('gp_Metadados', {resource: [ {string: 'tabela3_ligada', module: 'opcoes_tabelas', description: this.emuso3, created_by: this.$session.get('user_ID')} ]}, '', '1'))
          Promise.all(p).then(
            (a) => {
              document.location.reload()
            },
            (a) => {
              this.loading = false
            }
          )
        },
        (k) => {
          alert('Falha ao redefinir nomes... Recarregue essa tela e tente novamente.')
          this.loading = false
        }
      )
    },
    recarregar: function (index) {
      this.listar('gp_Metadados', 'id', '(module LIKE opcoes_tabelas)').then(
        (m) => {
          for (var i = 0; i < m.length; i++) {
            if (m[i].string === 'tabela1') {
              this.tabela1 = m[i].description
            }
            if (m[i].string === 'tabela2') {
              this.tabela2 = m[i].description
            }
            if (m[i].string === 'tabela3') {
              this.tabela3 = m[i].description
            }
            if (m[i].string === 'tabela1_ligada') {
              this.emuso1 = true
            }
            if (m[i].string === 'tabela2_ligada') {
              this.emuso2 = (m[i].description === '1')
            }
            if (m[i].string === 'tabela3_ligada') {
              this.emuso3 = (m[i].description === '1')
            }
          }
          this.loading = false
        }, (m) => {
          // Cria as opções (pois não existe)
          this.inserir('gp_Metadados', {resource: [ {string: 'tabela1', module: 'opcoes_tabelas', description: this.tabela1, created_by: this.$session.get('user_ID')} ]}, '', '1')
          this.inserir('gp_Metadados', {resource: [ {string: 'tabela2', module: 'opcoes_tabelas', description: this.tabela2, created_by: this.$session.get('user_ID')} ]}, '', '1')
          this.inserir('gp_Metadados', {resource: [ {string: 'tabela3', module: 'opcoes_tabelas', description: this.tabela3, created_by: this.$session.get('user_ID')} ]}, '', '1')
          this.inserir('gp_Metadados', {resource: [ {string: 'tabela1_ligada', module: 'opcoes_tabelas', description: this.emuso1, created_by: this.$session.get('user_ID')} ]}, '', '1')
          this.inserir('gp_Metadados', {resource: [ {string: 'tabela2_ligada', module: 'opcoes_tabelas', description: this.emuso2, created_by: this.$session.get('user_ID')} ]}, '', '1')
          this.inserir('gp_Metadados', {resource: [ {string: 'tabela3_ligada', module: 'opcoes_tabelas', description: this.emuso3, created_by: this.$session.get('user_ID')} ]}, '', '1')
          this.loading = false
        }
      )
    },
    limitar(e){
      return String(e).substring(0,20);
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading
  }
}
</script>